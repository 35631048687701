import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import mysterySound from "../assets/mystery.mp3";

const MysteryHome = () => {
  const [isSoundOn, setSoundOn] = useState(true);
  const [showReveal, setShowReveal] = useState(false);
  const [revealText, setRevealText] = useState("");
  const [timer, setTimer] = useState(0); // Initialize from localStorage
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [chestUnlocked, setChestUnlocked] = useState(false);

  const contractAddress = "BGJs9ngsJaXAqETGKRKipcxSJSWrzreBqQjWEFdYpump";

  useEffect(() => {
    // Load timer from localStorage or initialize a 12-hour countdown
    const storedEndTime = localStorage.getItem("arkhanisCountdown");
    const endTime = storedEndTime
      ? parseInt(storedEndTime, 10)
      : Date.now() + 12 * 60 * 60 * 1000; // 12 hours from now

    localStorage.setItem("arkhanisCountdown", endTime);

    const interval = setInterval(() => {
      const remainingTime = Math.max(0, Math.floor((endTime - Date.now()) / 1000));
      setTimer(remainingTime);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleRevealClick = () => {
    const loreFragments = [
      "The Arkhanis veil lifts for those who dare.",
      "Fragments of truth are hidden in the void.",
      "A realm beyond understanding calls to you.",
      "Seek the ancient whispers and find your destiny.",
    ];
    const randomLore =
      loreFragments[Math.floor(Math.random() * loreFragments.length)];
    setRevealText(randomLore);
    setShowReveal(true);
    setTimeout(() => setShowReveal(false), 8000);

    // Unlock the chest if countdown is at 0
    if (timer === 0) {
      setChestUnlocked(true);
    }
  };

  const handleTooltip = () => {
    navigator.clipboard.writeText(contractAddress);
    setTooltipVisible(true);
    setTimeout(() => setTooltipVisible(false), 2000);
  };

  useEffect(() => {
    const audio = new Audio(mysterySound);
    audio.loop = true;
    if (isSoundOn) {
      audio.play().catch(() => {});
    }
    return () => audio.pause();
  }, [isSoundOn]);

  const formatTime = (time) => {
    const hours = String(Math.floor(time / 3600)).padStart(2, "0");
    const minutes = String(Math.floor((time % 3600) / 60)).padStart(2, "0");
    const seconds = String(time % 60).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <Container>
      <AnimatedBackground />
      <Content>
        <Header>
          <Title>The Arkhanis Obelisk</Title>
          <TimerText>
            Countdown: <strong>{formatTime(timer)}</strong>
          </TimerText>
        </Header>
        <InteractiveArea>
          <EnergyField onClick={handleRevealClick}>
            <Portal>
              <PortalGlow />
            </Portal>
          </EnergyField>
          {showReveal && <RevealPopup>{revealText}</RevealPopup>}
          {chestUnlocked && (
            <ChestPopup>
              <h2>Chest Unlocked!</h2>
              <p>You have unlocked a mysterious reward!</p>
            </ChestPopup>
          )}
        </InteractiveArea>
        <ContractSection>
          <ContractText>Contract Address:</ContractText>
          <ContractAddress onClick={handleTooltip}>{contractAddress}</ContractAddress>
          {tooltipVisible && <Tooltip>Copied to clipboard!</Tooltip>}
        </ContractSection>
        <Footer>
          <SocialLink
            href="https://x.com/arkhanissol"
            target="_blank"
            whileHover={{ scale: 1.2 }}
          >
            X
          </SocialLink>
        </Footer>
        <SoundToggle onClick={() => setSoundOn(!isSoundOn)}>
          {isSoundOn ? "🔊" : "🔇"}
        </SoundToggle>
      </Content>
    </Container>
  );
};

export default MysteryHome;

// Styled Components
const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
  font-family: 'Poppins', sans-serif;  // Changed back to Poppins
  text-align: center;
`;

const AnimatedBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, #000, #0a0a0a, #1a1a1a);
  animation: ${keyframes`
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  `} 20s infinite;
  filter: blur(30px);
  z-index: -1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  position: relative;
`;

const Header = styled.div`
  margin-bottom: 30px;
`;

const Title = styled.h1`
  font-size: clamp(2rem, 5vw, 4rem);
  color: #ffd700;
  text-shadow: 0 0 15px #ffd700, 0 0 30px #ffaa00;
`;

const TimerText = styled.p`
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  color: #bbb;
`;

const InteractiveArea = styled.div`
  position: relative;
  margin-top: 20px;
`;

const EnergyField = styled.div`
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.1), rgba(255, 215, 0, 0.3));
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 50px rgba(255, 215, 0, 0.5);
`;

const Portal = styled(motion.div)`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: conic-gradient(from 180deg, #333, #000, #222);
  animation: ${keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  `} 15s linear infinite;
`;

const PortalGlow = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0 0 20px #ffd700, 0 0 60px rgba(255, 215, 0, 0.5);
`;

const RevealPopup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid #ffd700;
  border-radius: 10px;
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  color: #ffd700;
  animation: ${keyframes`
    0% { opacity: 0; transform: translate(-50%, -60%); }
    100% { opacity: 1; transform: translate(-50%, -50%); }
  `} 0.5s ease-in-out;
`;

const ChestPopup = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -60%);
  padding: 20px;
  background: rgba(0, 0, 0, 0.9);
  border: 2px solid #ffd700;
  border-radius: 10px;
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  color: #ffd700;
  animation: ${keyframes`
    0% { opacity: 0; transform: scale(0.8); }
    100% { opacity: 1; transform: scale(1); }
  `} 0.5s ease-in-out;
`;

const ContractSection = styled.div`
  margin-top: 30px;
`;

const ContractText = styled.h3`
  color: #bbb;
  font-size: clamp(1rem, 2vw, 1.2rem);
`;

const ContractAddress = styled.span`
  color: #ffd700;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
`;

const Tooltip = styled.div`
  margin-top: 10px;
  background: rgba(0, 0, 0, 0.8);
  color: #ffd700;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: clamp(0.8rem, 2vw, 1rem);
`;

const Footer = styled.div`
  margin-top: 20px;
`;

const SocialLink = styled(motion.a)`
  font-size: clamp(1.5rem, 4vw, 2rem);
  margin: 0 10px;
  color: #ffd700;
  cursor: pointer;
  text-decoration: none; // Ensures no underline on X

  &:hover {
    color: #fff;
  }
`;

const SoundToggle = styled(motion.button)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.5);
  color: #ffd700;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  z-index: 1000;
  @media (max-width: 768px) {
    display: none;
  }
`;